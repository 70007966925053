@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@100;200;300;400;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

body {
	margin: 0;
	font-family: 'Source Sans Pro', 'Mulish', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
